<template>
  <div>

    <div style="margin-bottom: 30px;padding: 0px 20px; text-indent: 2rem; color: #666666;line-height: 25px;">
      <!-- <p style="font-size: 18px;">填报须知：</p> -->

      2023年美术类<span style="color:red">文科</span>合格人数<span style="color:red">54358</span>人，本科计划一共<span
        style="color:red">20131</span>人，专科计划一共 <span style="color:red">13237</span> 人。
      2023年美术类<span style="color:red">理科</span>合格人数<span style="color:red">9398</span>人,本科计划一共<span
        style="color:red">2644人</span>，专科计划一共<span style="color:red">3454</span>人。
      河南省美术与设计类特点是报考人数多、合格率高、招生计划少，所以各位考生应根据自己成绩理性报考，过线不代表稳妥，各批次尽量兼报，切勿盲目冲击。

    </div>

    <conditional-search @onSearch="onSearch"></conditional-search>

    <div style="margin-top: 30px;border-top: 1px solid #dfdfdf;">
      <form-list v-for="(item, i) in tableList" :key="i" :item="item" :oldScoreArt="oldScoreArt" :oldScore="oldScore"
        :oldLine="oldLine" :art_batch="art_batch"></form-list>
    </div>

    <!-- 分页 -->
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="page.pageSize" layout=" prev, pager, next"
            :total="page.total" :current-page="page.pageNum">
          </el-pagination>
        </div>
      </template>
    </div>

    <div style="border: 1px solid #dfdfdf;margin-top: 20px;padding: 20px;color: #666666;">

      备注：<br>
      <br>
      1、志愿建议：系统根据你的专业课文化课成绩参照历史数据经过换算后给你的填报策略（<span
        style="color:red">冲、稳、保、垫仅仅代表填报策略分段区间，与其字面意义没有任何关系，美术类招生计划少几乎不存在所谓的稳保垫
      </span>）建议；分数相对高的可能就不存在冲的院校/专业，分数相对低的可能全部是冲的院校/专业；该建议仅供参考！考生还需根据当年招生计划、专业热门程度、自身职业规划等状况综合决定填报方案。
      <br>
      2、投档分：是指当年该院校/专业的投档最低综合分
      <br>
      3、线差投：
      是指当年该院校/专业的投档最低综合分减去该批次省控线按照该院校/专业的规则要求计算出的综合分之差。
      <br>
      4、换算分：是指考生今年专业分和文化分换算到当年后按照当年院校/专业的规则计算出综合分。<br>
      5、线差换：是指考生今年专业分和文化分换算到当年后按照当年院校/专业的规则计算出综合分减去当年该批次省控线按照该院校/专业的规则要求计算出的综合分之差。<br>
      6、分差：换算分和投档分之差<br>
      <!-- 7、美术类志愿填报存在上线人数多、招生计划少的特点，比如2022年A段上线人数是29673人A段招生计划才12009人，A段B段一共招生计划才21711人，在平行志愿的规则下，志愿方案总体要以稳为主，即便是双过线也不代表一定能走成该批次。<br> -->
      7、省外院校由于招生计划少，院校/专业数据有可能存在较大浮动，建议谨慎填报

    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import ConditionalSearch from './components/ConditionalSearch'
import FormList from './components/FormList'
export default {
  name: '',
  props: ['art_batch', 'oldScoreArt', 'oldScore', 'oldLine'],
  components: {
    ConditionalSearch,
    FormList
  },
  data() {
    return {
      check: {},
      tableList: [],
      change: 1,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    art_batch() {
      this.tableList = []
      this.getCollege(this.check)
    },
    oldScore() {
      this.getCollege(this.check)
    }
  },
  created() {
    this.$emit("Nav", 2);
    this.getCollege(this.check);
  },
  methods: {
    onSearch(data) {
      this.check = data
      this.page.pageNum = 1
      this.getCollege(data)
    },
    getCollege(item) {
      console.log(this.oldScore)
      if (this.oldScore[1]) {
        let data = {
          city_id: item.city_id,
          nature_id: this.check.nature_id,
          specific_code: this.check.specific_code,
          type_id: this.check.type_id,
          lqgz_id: this.check.lqgz_id,
          school_tip_id: this.check.schoolTipId,
          rule_id: this.check.rule_id,
          tuition: this.check.tuition,
          score: this.oldScore[1],
          z_score: this.oldScoreArt[1],
          art_batch: this.art_batch,
          is_wenli: this.userInfo.is_wenli,
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,

        }
        this.$fecth.post('art_t_school_score/getList', data).then((res) => {
          this.tableList = res.lists
          this.page.total = res.count
          this.loading = false
        })
      }

    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getCollege(this.check)
    }

  },
};
</script>

<style scoped lang='less'></style>
